body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.controls {

}

.content-wrapper {

}

.audio-player {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.content-wrapper {
  text-align: center;
}

canvas {

}

* {
  padding: 0;
  margin: 0;
}

body {
  min-height: 100vh;
  background: black;
  color: white;
}

/* TODO: Define base styles */

button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 5px;
  margin: 5px;
}

#root {
  min-height: 100vh;
}

.App {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navigation {
  color: white;
  width: 100%;
  outline-offset: -1px;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.navigaton ul {
  padding: 0;
  margin: 0;
}

.navigation ul li {
  display: inline-block;
  padding: 5px;
}

.main-content {
  outline-offset: -1px;
  flex: 1 1;
}

