* {
  padding: 0;
  margin: 0;
}

body {
  min-height: 100vh;
  background: black;
  color: white;
}

/* TODO: Define base styles */

button {
  appearance: none;
  padding: 5px;
  margin: 5px;
}

#root {
  min-height: 100vh;
}

.App {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navigation {
  color: white;
  width: 100%;
  outline-offset: -1px;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.navigaton ul {
  padding: 0;
  margin: 0;
}

.navigation ul li {
  display: inline-block;
  padding: 5px;
}

.main-content {
  outline-offset: -1px;
  flex: 1;
}
