.controls {

}

.content-wrapper {

}

.audio-player {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.content-wrapper {
  text-align: center;
}

canvas {

}
